import {
    Module,
    VuexModule,
} from 'vuex-module-decorators';

@Module({
    namespaced: true,
    name: 'SiteConfigModule',
})
class SiteConfigModule extends VuexModule {
    private cbaLink: string = 'https://www.quantum.com/CBA';
    private cbaDocLink: string = 'http://www.quantum.com/CBADocs';
    private quantumSupportLink: string = 'https://www.quantum.com/serviceandsupport/index.aspx';
    private customText: string = 'Product performance and health monitoring.';
    private backendApi: string = '';
    private recaptcha: string = '6Lce1o8UAAAAACfMvG-WiITMrUZnXBKEzAtPRbH2';
    private uiVersion: string = 'v7-16-0-2774e092';
    private support: string = 'https://www.quantum.com/CBADocs';
    private quantum: string = 'https://www.quantum.com';
    private myQuantum: string = 'https://myservices.quantum.com';
    private myQuantumCreateCaseForm: string = 'https://myservices.quantum.com/case/create';

    public get recaptchaKey(): string {
        return this.recaptcha;
    }

    public get quantumLink(): string {
        return this.quantum;
    }

    public get supportLink(): string {
        return this.support;
    }

    public get getBackend(): string {
        return this.backendApi;
    }

    public get getCbaLink(): string {
        return this.cbaLink;
    }

    public get cbaDocsLink(): string {
        return this.cbaDocLink;
    }

    public get corporateSupportLink(): string {
        return this.quantumSupportLink;
    }

    public get loginCustomText(): string {
        return this.customText;
    }

    public get getUIVersion(): string {
        return this.uiVersion;
    }

    public get getRootFolder(): string {
        return process.env.BASE_URL;
    }

    public get isLocalStartApp(): boolean {
        return window.location.hostname === 'localhost';
    }

    public get myQuantumLink(): string {
        return this.myQuantum;
    }

    public get myQuantumCreateCaseLink(): string {
        return this.myQuantumCreateCaseForm;
    }
}
export default SiteConfigModule;
